import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Video from "../components/video"

import indexStyles from "../styles/pages/index.module.css"

import purpleRain from "../audio/purple-rain.mp3"
import creativeExpressions from "../images/creative-expressions.png"
import contactVideo from "../videos/contact-vid.mp4"
import contactPosterImg from "../images/contact-poster-img.png"
import aboutVideo from "../videos/truenorth-animation.mp4"
import aboutPosterImg from "../images/index-poster-img.png"
import design1 from "../images/design-1.png"
import aboutPic7 from "../images/about-pic-7.jpg"
import aboutPic8 from "../images/about-pic-8.jpg"
import aboutPic9 from "../images/about-pic-9.jpg"
import aboutPic10 from "../images/about-pic-10.jpg"
import aboutPic11 from "../images/about-pic-11.jpg"
import aboutPic12 from "../images/about-pic-12.jpg"

const IndexPage = () => {
  function play(e) {
    e.preventDefault();
    var music = document.getElementById("music");
    music.play();
  }

  return (
    <Layout location="home">
      <SEO title="Home" />
      <div className={ indexStyles.aboutSection }>
        <div className={ indexStyles.imageRow }>
          <img src={ aboutPic7 } alt=""/>
          <img src={ aboutPic8 } alt=""/>
          <img src={ aboutPic9 } alt=""/>
          <img src={ aboutPic10 } alt=""/>
          <img src={ aboutPic11 } alt=""/>
          <img src={ aboutPic12 } alt=""/>
        </div>
        <div className={ indexStyles.logoAnimation }>
          <Video src={ aboutVideo } poster={ aboutPosterImg } className={ indexStyles.aboutVideo } />
        </div>
        <div className={ indexStyles.aboutTitle }>
          <div className={indexStyles.aboutTitleWrapper }>
            <h3>It's all about finding your way to</h3>
            <audio id="music" src={ purpleRain }></audio>
            <div><a onClick={ play }><img src={ creativeExpressions } /></a></div>
            <Link to="/about/">Learn More</Link>
          </div>
        </div>
      </div>
      <div className={ indexStyles.servicesSection }>
        <div className={ indexStyles.servicesPics }>
          <img src={ design1 } alt=""/>
        </div>
        <div className={ indexStyles.servicesText }>
          <div className={ indexStyles.servicesTextWrapper }>
            <h1>Concept. Design. Execution.</h1>
            <p>It sounds like a stretch doing the nitty-gritty of graphics design, from conceptualization to the final output. At True North, we do the whole gamut of innovative renderings delivered to you fast and in high quality. Our years of experience in the business have produced satisfied clients, and we're still growing.</p>
            <Link to="/services/">Check Services</Link>
          </div>
        </div>
      </div>
      <div className={ indexStyles.contactSection }>
        <div className={ indexStyles.contactVideoPortion }>
          <Video src={ contactVideo } poster={ contactPosterImg } className={ indexStyles.contactVideo } />
        </div>
        <div className={ indexStyles.contactText }>
          <div className={ indexStyles.contactTextWrapper }>
            <h1>Work With Us.</h1>
            <p>Imagineering through well-thought-out executions will get you to the right place and the right niche you dream to captivate.</p>
            <Link to="/contact/">Contact Us</Link>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default IndexPage
